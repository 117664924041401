<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/digitalService' }"
          >扩容提质服务</el-breadcrumb-item
        >
        <el-breadcrumb-item>一号密函</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div class="container">
      <div class="box">
        <p>
          一号密函提供多渠道的投递渠道，包括：运营商邮箱、
          智能消息、微信小程序、云闪付小程序、云盘等，
          可以为消息通知、动账提醒、活动发布、回单及对账单接收等多种业务提供客户触达。
          用户可根据自身使用习惯，
          选择触达方式，企业也可根据特定需求调整触达渠道。
        </p>
        <p>
          <span>1.短信</span>
          短信投递使用短信方式传递信息给客户的服务，它具有快速、方便、覆盖面广等优点，是现代企业与客户沟通的重要手段之一。通过短信投递，企业可以及时将各种通知、促销信息、验证码等重要信息传递给客户，提高客户满意度和忠诚度。
          企业信息传递，快速、方便、高效！我们提供稳定、高效的短信投递服务，一键发送，立即触达。让您与您的客户随时随地保持沟通。
        </p>
        <p>
          <span>2.运营商邮箱</span>
          手机短信+邮箱是目前使用最为广泛的投递渠道。客户通过手机移动端可以方便快捷地进行查看、确认及下载，免去客户前往线下进行操作的麻烦。
          运营商邮箱是与客户手机号码绑定，客户手机号开户时同步开通，即中国移动139邮箱，中国联通沃邮箱以及中国电信189邮箱，客户不必自行注册即可使用。
          当信息投递到客户邮箱中后，会自动向客户手机号下发一条通知短信，客户可以通过点击短信中的链接进入邮箱查看及下载等后续操作。客户也可以选择通过app、网页、微信小程序、微信公众号等渠道登录运营商邮箱进行查看。
        </p>
        <p>
          <span>3.智能消息</span>
          智能（koomessage、5g）消息是在传统短信上的升级，
          智能消息具有富媒体展现形式，可以支持图片、按钮、文字、视频等，展现形式更加丰富，
          多种交互方式，一键直达客户。支持全流程的数据统计。
          当电子凭证投递到客户，客户通过通知消息进入“短消息app”进行账单查看及操作，
          操作流程简单。
        </p>
        <p>
          <span>4.微信小程序 </span>
          一号密函系统将用户的消息投递至微信小程序，
          用户登录微信小程序进行消息查看、下载等操作。
          同时可以联动企业微信、微信小程序、客户微信，实现客户与客户经理互动，
          并可通过企业微信及小程序进行其他业务的交叉营销。
        </p>
        <p>
          <span>5.云闪付小程序 </span>
          在云闪付APP上设置小程序。一号密函系统将用户的消息投递至小程序，用户登录云闪付小程序进行账单查看、下载等操作。
        </p>
        <p>
          <span>6.闪信（H5必达消息） </span>
          闪信是经三大运营商正规渠道分发的、将信息传达至手机，弹窗展示，用户直接阅读，信息不留存手机，不为用户制造垃圾信息。不被拦截软件拦截屏蔽，高效到达。
          用户收到信息后手机屏幕强制弹屏展示，用户需操作后界面才能退出，停留时间可以进行设置，对失联客户可进行强制触达。
        </p>
        <p>
          <span>7.云盘 </span>
          云盘服务为每一个生态中的企业客户提供的入网即享的云空间与云服务。
          企业在柜台进行回单、对账单等凭证业务订阅并选择通过云盘渠道进行回单/对账单的存储之后，通过银行预留的一个手机号注册或绑定云盘服务，即可进行后续外部凭证的自动采集与接收。
          采集到的回单、对账单、数电票等外部凭证会根据类型、企业及银行等不同来源自动分类并保存到相应文件夹中进行永久储存，您可以使用同一手机号通过app，小程序，pc客户端等不同渠道登录进行凭证查看及处理，或分享给他人进行协同财税银数据处理。
          可在pc客户端进行凭证的自动同步设置，设置完成后，每一封上传到云盘的凭证都将自动从云端同步到本地磁盘。同步完成后会进行提醒，您可以通过点击快捷图标直接打开凭证夹，更加方便、快捷地进行凭证浏览以及后续记账等操作。
          在进行本地账务处理后产生的新凭证及报表等数据也可以通过云盘的自动备份功能定时上传到云端，实现数据的云端储存异地备份，避免由于不慎操作或其他原因导致的本地数据丢失。
        </p>
      </div>
    </div>

    <div class="btn" @click="subInfo">立即体验</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    subInfo() {
      this.$router.push({
        path: "/info",
        query: { org: "sz" },
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}

.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.box {
  width: 1200px;
  padding: 30px 0;
  margin: 0 auto;
}

.img_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.img_box span {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}

.img_box img {
  display: block;
  width: 100%;
}

.simg_box {
  display: flex;
  justify-content: center;
}

.img_box1 {
  width: 50%;
}

.img_box1 img {
  width: 200px;
  height: 350px;
}

.box p {
  font-size: 18px;
  color: #333333;
  line-height: 34px;
  padding: 20px 0;
  text-align: justify;
}

.box p:first-child {
  padding: 0;
}

.box p span {
  display: block;
  font-weight: bold;
  margin: 10px 0;
}

.btn {
  width: 130px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
  background: #055afe;
  color: #fff;
  margin: 20px auto;
  cursor: pointer;
}

table tr th {
  text-align: center;
  padding: 5px;
  font-size: 14px;
}

table tr td {
  text-align: center;
  padding: 10px;
}
</style>
